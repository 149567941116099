html,
body {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  font-family: 'Raleway', 'san-serif';
  background-color: #efefef;
  -webkit-font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
}

input {
  -webkit-font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
}

#root {
  width: 100%;
}

.custom-table {
  --item-height: 1.625rem;
}

.custom-table > tbody > tr > td,
.custom-table > thead > tr > th {
  padding: 0.5rem;
  text-align: left;
}

.custom-table td > input,
.custom-table td > textarea,
.custom-table td > select {
  border: 1px solid black;
  border-radius: 0.375rem;
  padding-left: 0.5rem;
  padding-left: 0.5rem;
  /* This makes everything subcompoent have consistent width */
  width: 100%;
}

.custom-table input,
.custom-table select,
.custom-table .multi-select {
  /* This makes one-liner have consistent height */
  height: var(--item-height);
}

@media (max-width: 640px) {
  .custom-table > tbody > tr > td,
  .custom-table > tbody > tr,
  .custom-table > thead > th {
    display: block;
    font-size: 0.875rem;
  }
  .custom-table > tbody > tr > td,
  .custom-table > thead > th {
    padding: 0.3rem;
    text-align: center;
  }
}
