.customer-agreement-container {
    grid-area: coverage;
    max-width: 50rem;
}

.term-container {
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    width: 100%;  
}


.term-title {
    font-weight: bold;
}

.term-description {
    margin-top: 1rem;
}

.term-check {
    margin-top: 1rem;
    border: 2px solid #878787;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    cursor: pointer;
}

.term-check * {
    cursor: inherit;
}

.term-check input[type="checkbox"] {    
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid #878787;
    margin-right: 1rem;
}

.term-check input[type="checkbox"]:checked {    
    accent-color: #1EA2AE;
}

.term-check input[type="radio"] {
    width: 1.5rem;
    margin-right: 1rem;
}

.term-check input[type="radio"]:checked {
    accent-color: #1EA2AE;
}

.customer-agreement-container input[type="text"],.customer-agreement-container textarea {
    border-radius: 1rem;
    padding: 1rem;
    border: 2px solid #878787;
}


.proceed-esign:disabled {
    background-color: #9ca3af;
    border: 1px solid black;
    cursor: default;
}

.send-esign:disabled {
    background-color: #9ca3af;
}

.return-foxden {
    background-color: white;
    color: #69D259;
    border: 1px solid #69D259;
}

.proceed-esign {
    background-color: #f7943c;
}

.return-foxden:hover {
    background: #EEFFEC;
}

.policy-warning {
    position: relative;
    text-align: left;
    padding: 0.75rem 1.5rem;
    border-radius: 1rem;
    border-width: 0.2rem;
    border-color: #FFD64E;
    background-color: white;
    margin-bottom: 1rem;
}
  